<template>
  <div class="myPage">
    <el-container class="normalCon">
      <el-main>
        <i class="el-icon-arrow-left topLogo" @click="back()"></i>
        <div class="listTable">
          <div class="introMain">
            <h2>业务推广</h2>
            <h5>服务类：</h5>
            <div class="olList">
              <ol>
                <li>1、风水地理大师，时辰推算择日子。</li>
                <li>2、骨灰超度服务（操灵佛事、法事）布置超度灵堂（有固定中、小灵堂设置）。</li>
                <li>3、抬骨灰护灵仪式服务（礼节、礼仪，主持骨灰下葬全过程仪式）。</li>
                <li>4、乐队服务、电子礼炮服务。</li>
                <li>5、墓碑瓷像、墓碑翻新添字服务、草皮绿化服务。</li>
                <li>6、代客拜祭扫墓服务。</li>
              </ol>
            </div>
            <h5>祭拜品销售类：</h5>
            <div class="olList">
              <ol>
                <li>1、三牲、五色果盘及糖果，酒等贡品（需要预定）；</li>
                <li>2、鲜花：花圈、花篮、鲜花束、单支鲜花、花瓣（需要预定）；</li>
                <li>3、骨灰塔、蛊、盒（云石、陶瓷、实木）；</li>
                <li>4、寿衣（唐装、西装、中山装、龙装、棉衣、寿被、寿鞋、寿枕、孝服）；</li>
                <li>5、香、烛、冥币、衣宝、灵屋纸房，红色铁油、金色铁油、电子香、电子烛；</li>
                <li>6、烟花、炮竹、竹竿。</li>
              </ol>
            </div>
            <p>自己操办手续繁琐，交给专业团队为你全程指导操办，让你省力、省心、省事。</p>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    // 返回上一页
    back() {
      this.$router.replace("Home");
    },
  },
}
</script>

<style scoped lang="scss">
.listTable {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  .introMain {
    position: relative;
    padding: 15px 15px 0;
    h2 {
      font-size: 1.6rem;
      padding: 10px 0 15px;
      text-align: center;
    }
    h5 {
      font-size: 1.4rem;
      padding: 10px 0 15px;
      text-align: center;
    }
    ol {
      margin: 0 0 15px;
      li {
        font-size: 1.2rem;
        line-height: 1.8;
      }
    }
    p {
      font-size: 1.2rem;
      line-height: 1.8;
      text-indent: 2em;
      margin: 0 0 10px;
    }
  }
}

</style>
